import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Divider,
  Modal,
  Spin,
  Tag,
  Tooltip,
  message,
} from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import DocAvatar from "../../assest/img/DocAvatar.png";
import "./checkout.css";
import axios from "axios";
import { SnackbarContext } from "../../component/UtilComponents/SnackbarUtil/SnackbarContext";
import PatientAvatar from "../../assest/img/patient.png";
const Checkout = () => {
  const navigate = useNavigate();
  const { openSnackbar } = useContext(SnackbarContext);

  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { doctorData, patientData, appointmentDetail } = location?.state;
  const consultationOptions = {
    true: {
      label: "First Consult",
      amount: location?.state?.payload?.amount,
    },
    false: {
      label: "Follow Up",
      amount: location?.state?.payload?.amount,
    },
  };
  console.log("locn state", location?.state);

  const amount = appointmentDetail?.amount;

  const [htmlContent, setHtmlContent] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleAppointment = (paymentMethod) => {
    let formData = {
      ...location?.state?.payload,
      payment_method: paymentMethod,
    };
    return axios
      .post(`apiV1/appointment/`, formData)
      .then((res) => {
        return {
          formData: formData,
          appointmentDetails: res?.data,
        };
      })
      .catch((err) => {
        openSnackbar(
          err?.response?.data?.message ?? "Something went wrong!",
          "error"
        );
        return {
          formData: null,
          error: err,
        };
      });
  };

  const handlePayOnCounterAppointment = () => {
    const service = [
      {
        charge:
          consultationOptions[location?.state?.payload?.is_first_visit].amount,
        service:
          consultationOptions[location?.state?.payload?.is_first_visit].label,
      },
    ];
    setLoading(true);
    handleAppointment("cash")
      .then((response) => {
        let { formData, appointmentDetails } = response;
        console.log({ response });
        let values = {
          ...formData,
          appointment: appointmentDetails?.id,
          services_opted: JSON.stringify(service),
          current_service_opted: JSON.stringify(service),
          is_paid: true,
          payment_method: "cash",
        };
        axios
          .post(`/apiV1/offline-payment/`, values)
          .then((res) => {
            setTimeout(() => {
              navigate("/history");
            }, 2000);
            message.success("Payment collected!");
          })
          .catch((err) => {
            console.log("err", err?.response);
            message.error(
              err?.response?.data?.message ?? "Something went wrong"
            );
          });
      })
      .catch((err) => {
        console.log("err", err?.response);
        message.error(err?.response?.data?.message ?? "Something went wrong");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleAppointmentOnline = () => {
    const service = [
      {
        charge:
          consultationOptions[location?.state?.payload?.is_first_visit].amount,
        service:
          consultationOptions[location?.state?.payload?.is_first_visit].label,
      },
    ];
    setLoading(true);
    handleAppointment("online")
      .then((response) => {
        let { formData, appointmentDetails } = response;
        const values = {
          ...formData,
          appointment: appointmentDetails?.id,
          services_opted: JSON.stringify(service),
          current_service_opted: JSON.stringify(service),
          url: "https://welldonehealth.in",
        };
        axios
          .post(`apiV1/payment/`, values)
          .then((res) => {
            message.success("Redirecting to payment");
            if (res?.data?.success == true) {
              window.location.href =
                res?.data?.data?.instrumentResponse?.redirectInfo?.url;
            }
          })
          .catch((err) => {
            console.log("err", err?.response);
          });
      })
      .catch((err) => {
        console.log("err", err?.response);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const branch = doctorData?.establishment?.find(
    (item) => item?.id == appointmentDetail?.branch
  );

  return (
    <>
      {htmlContent && (
        <iframe
          style={{ position: "fixed", zIndex: 1000 }}
          srcDoc={htmlContent}
          width="100%"
          height="100%"
          frameBorder="0"
          title="HTML Content"
        />
      )}

      <div className="mx-1 px-2 py-3">
        <Spin size="large" spinning={loading}>
          <h4>Review and complete</h4>
        </Spin>
        <div className="row">
          <div className="col-md-8 p-3">
            <Card>
              <div className="d-flex justify-content-between mb-2">
                <h5 className="m-0">Patient Details</h5>
                <Tag
                  className="th-pointer"
                  onClick={() => {
                    navigate(-1);
                  }}
                  icon={<i class="far fa-edit mr-1"></i>}
                >
                  Change Patient
                </Tag>
              </div>
              <div className="th-red-line"></div>
              <div className="th-blue-line"></div>
              <div className="d-flex my-3">
                <img
                  alt="pat_img"
                  src={patientData?.avatar ?? PatientAvatar}
                  width="50px"
                  height="50px"
                  className="th-br-10 bg-white shadow-sm rounded-circle"
                />
                <div className="pl-2">
                  <div
                    className="pl-3"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div className="d-flex align-items-center ">
                      <h5 className="m-0 pr-1">
                        {[
                          patientData?.first_name ?? "",
                          patientData?.middle_name ?? "",
                          patientData?.last_name ?? "",
                        ]?.join(" ")}
                      </h5>
                      <div>
                        ({patientData?.age},
                        {patientData?.gender?.substring(0, 1)?.toUpperCase()})
                      </div>
                    </div>
                    <div className="d-flex flex-wrap mt-2">
                      {patientData?.contact && (
                        <Tag icon={<i class="fas fa-phone-alt mr-1"></i>}>
                          {patientData?.contact}
                        </Tag>
                      )}
                      {patientData?.address && (
                        <Tag icon={<i class="fas fa-map-marker-alt mr-1"></i>}>
                          {patientData?.address}
                        </Tag>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-2">
                <p className="m-0 th-fw-600">Patient Vitals:</p>
                <div className="d-flex flex-wrap  ">
                  {patientData?.weight && (
                    <Tag className="m-1">
                      <span>
                        <i className="th-grey fas fa-weight mr-2"></i>
                        {patientData?.weight}kg
                      </span>
                    </Tag>
                  )}
                  {patientData?.temperature && (
                    <Tag className="m-1">
                      <span>
                        <i className="th-grey fas fa-temperature-high mr-2"></i>
                        {patientData?.temperature}° C
                      </span>
                    </Tag>
                  )}
                  {patientData?.menstrual && (
                    <Tag className="m-1">
                      <span>
                        <img
                          src="https://cdn-icons-png.flaticon.com/128/4080/4080345.png"
                          height={16}
                          className="mr-2"
                          alt="periods"
                        />
                        {patientData?.menstrual}M/c
                      </span>
                    </Tag>
                  )}
                  {patientData?.low_bp && patientData?.high_bp && (
                    <Tag className="m-1">
                      <span>
                        <img
                          src="https://cdn-icons-png.flaticon.com/128/811/811932.png"
                          height={16}
                          className="mr-2"
                          alt="periods"
                        />
                        {patientData?.low_bp}/{patientData?.high_bp} mm/hg
                      </span>
                    </Tag>
                  )}
                  {patientData?.respiratory_rate && (
                    <Tag className="m-1">
                      <span>
                        <img
                          src="https://cdn-icons-png.flaticon.com/128/2833/2833035.png"
                          height={16}
                          className="mr-2"
                          alt="periods"
                        />
                        {patientData?.respiratory_rate}/min
                      </span>
                    </Tag>
                  )}
                  {patientData?.glucose_level && (
                    <Tag className="m-1">
                      <span>
                        <img
                          src="https://cdn-icons-png.flaticon.com/128/3209/3209174.png"
                          height={16}
                          className="mr-2"
                          alt="periods"
                        />
                        {patientData?.glucose_level} mg/DL
                      </span>
                    </Tag>
                  )}
                  {patientData?.pulse && (
                    <Tag className="m-1">
                      <span>
                        <img
                          src="https://cdn-icons-png.flaticon.com/128/8226/8226674.png"
                          height={16}
                          className="mr-2"
                          alt="periods"
                        />
                        {patientData?.pulse}/min
                      </span>
                    </Tag>
                  )}
                  {patientData?.height && patientData?.height_unit && (
                    <Tag className="m-1">
                      <span>
                        <img
                          src="https://cdn-icons-png.flaticon.com/128/2330/2330995.png"
                          height={16}
                          className="mr-2"
                          alt="periods"
                        />
                        {patientData?.height}
                        {patientData?.height_unit}
                      </span>
                    </Tag>
                  )}
                  {patientData?.blood_group && (
                    <Tag
                      icon={
                        <img
                          src="https://cdn-icons-png.flaticon.com/128/8879/8879089.png"
                          height={14}
                          className="mr-1"
                          alt="periods"
                        />
                      }
                      className=""
                    >
                      <span style={{ textTransform: "uppercase" }}>
                        {patientData?.blood_group}
                      </span>
                    </Tag>
                  )}
                </div>
              </div>

              {/* DOCTOR DETAILS */}
              <div className="d-flex justify-content-between mt-5 mb-2">
                <h5 className="m-0">Appointment Details</h5>
                <Tooltip title="Change Appointment Type">
                  <Tag
                    className="th-pointer"
                    onClick={() => {
                      navigate(-2);
                    }}
                    icon={<i class="far fa-edit mr-1"></i>}
                  >
                    {appointmentDetail?.is_equeue ? "E-Queue" : "Slot booking"}
                  </Tag>
                </Tooltip>
              </div>
              <div className="th-red-line"></div>
              <div className="th-blue-line"></div>
              <div className="d-flex my-3">
                <img
                  src={
                    doctorData?.image_gallery?.find(
                      (i) => i?.file_type === "image"
                    )?.image ?? DocAvatar
                  }
                  className="rounded-circle shadow-sm"
                  width="50px"
                  height="50px"
                  alt="doc_avatar"
                />
                <div className="pl-3">
                  <h5>
                    {doctorData?.suffix}{" "}
                    {[
                      doctorData?.first_name ?? "",
                      doctorData?.middle_name ?? "",
                      doctorData?.last_name ?? "",
                    ]?.join(" ")}
                  </h5>
                  <div className="d-flex flex-wrap">
                    {doctorData?.education_and_background?.map(
                      (item, index) => {
                        return (
                          <>
                            <Tag key={index} className="mb-2 ">
                              <i className="fas fa-graduation-cap"></i>{" "}
                              {item?.qualification}
                            </Tag>
                          </>
                        );
                      }
                    )}
                    <Tag className="mb-1">
                      <i class="fas fa-briefcase text-primary"></i>{" "}
                      {doctorData?.total_experience} Years Overall Experience
                    </Tag>
                  </div>
                </div>
              </div>
              <div className="mt-2">
                <p className="m-0 th-fw-600">Clinic Details:</p>
                <div className="d-flex flex-wrap">
                  <Tag
                    className="mt-1"
                    icon={<i class="far fa-building mr-1"></i>}
                  >
                    {branch?.establishment_name}
                  </Tag>
                  <Tag
                    className="mt-1"
                    icon={<i class="fas fa-map-marker mr-1 text-danger"></i>}
                  >
                    {branch?.branch}
                  </Tag>
                </div>
              </div>
            </Card>
          </div>
          <div className="col-md-4 p-3">
            <Card>
              <h5>Payment Summary</h5>
              <div className="th-red-line"></div>
              <div className="th-blue-line"></div>
              <div className="my-3">
                <div className="d-flex justify-content-between">
                  <div className="">Fees of Consultation</div>
                  <div className="">₹ {amount}</div>
                </div>
                <Divider className="mt-3 mb-0" />
                <div className="d-flex justify-content-between mt-2">
                  <div className="">
                    <strong>Total</strong>
                  </div>
                  <div className="">₹ {amount}</div>
                </div>
                <div className="my-3">
                  <Button
                    onClick={handlePayOnCounterAppointment}
                    className="w-100"
                  >
                    Pay on Counter
                  </Button>
                  <div className="text-center my-2 th-16">or</div>
                  <Button
                    onClick={handleAppointmentOnline}
                    className="w-100"
                    type="primary"
                  >
                    Pay Online
                  </Button>
                </div>
                <div
                  className="w-100 p-1 th-br-4 mt-3"
                  style={{ background: "#F0F0F0" }}
                >
                  *We're required by law to collect sales tax and applicable
                  fees for certain tax authorities.
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
      <Modal
        // title="Appointment Booked Successfully!"
        maskClosable={false}
        open={isModalOpen}
        // onOk={handleOk}
        // onCancel={handleCancel}
        centered
        closable={false}
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={() => {
              navigate("/");
            }}
          >
            Go to Home
          </Button>,
          <Button
            type="primary"
            loading={loading}
            onClick={() => {
              navigate("/history");
            }}
          >
            Go to Appointment History Page
          </Button>,
        ]}
      >
        <p style={{ padding: 3 }}>
          <b>Appointment Booked Successfully!</b>
        </p>
      </Modal>
    </>
  );
};

export default Checkout;
